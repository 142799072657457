import * as React from 'react';
import classNames from 'classnames';
import styles from './list.module.css';

type AllowedTagNames = 'ul' | 'ol';

type ListProps = {
  children?: any;
  className?: string;
};

const createList = ({ tagName, children, className }: ListProps & { tagName: AllowedTagNames }) => {
  return React.createElement(
    tagName,
    {
      className,
    },
    children,
  );
};

export const UnorderedList = ({ ...props }: ListProps) => {
  return createList({ tagName: 'ul', className: classNames(styles.list, styles.bullet), ...props });
};

export const OrderedList = ({ ...props }: ListProps) => {
  return createList({ tagName: 'ol', className: classNames(styles.list, styles.number), ...props });
};
