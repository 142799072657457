import * as React from 'react';
import classNames from 'classnames';
import { PageTemplateProps } from 'shared/types/pages';
import {
  Spacing,
  TextPriority2,
  TextPriority3,
  TextPriority4,
} from 'ui/components/atoms/typography/typography';
import { BannerDetail } from 'ui/components/molecules/banner/banner_detail';
import { defaultHeading, GetInTouch } from 'ui/components/molecules/get_in_touch/get_in_touch';
import { Section } from 'ui/components/molecules/section/section';
import { Vimeo } from 'ui/components/molecules/vimeo/vimeo';
import { Container } from 'ui/components/organisms/container/container';
import { Col, Row } from 'ui/components/organisms/grid/grid';
import { FeaturedTreatment } from 'ui/components/organisms/featured_treatment/featured_treatment';
import { Featured } from 'ui/components/molecules/featured/featured';
import { Carousel } from 'ui/components/organisms/carousel/carousel';
import { Faq } from 'ui/components/molecules/faq/faq';
import styles from './treatment_detail.module.css';
import { PageTreatmentDetailProps } from 'templates/treatment_detail';
import { WYSIWYG } from 'ui/components/molecules/wysiwyg/wysiwyg';
import { UnorderedList } from 'ui/components/atoms/list/list';
import { Picture } from 'ui/components/atoms/picture/picture';
import { FAQBlocks as FAQBlocksProps } from 'templates/treatment_detail';
import { createRestrictedModal } from 'ui/components/molecules/modal/modal';
import { Main } from 'ui/components/organisms/main/main';
import { JsonLD } from 'ui/components/molecules/json_ld/json_ld';

const FAQBlocks = (block: FAQBlocksProps) => {
  switch (block.fieldGroupName) {
    case 'page_Acftemplatetreatmentdetail_TreatmentDetail_Faqs_Items_Individual':
      return { question: block.question, answer: block.answer };
    case 'page_Acftemplatetreatmentdetail_TreatmentDetail_Faqs_Items_Relational':
      return {
        question: block.relational.acf.faq.question,
        answer: block.relational.acf.faq.answer,
      };
    default:
      throw new Error('Unreachable Switch Error: FAQBlocks');
  }
};

export const TreatmentDetail = ({ data }: PageTemplateProps<PageTreatmentDetailProps>) => {
  const {
    banner,
    video,
    enableVideo,
    introduction,
    benefits,
    enableTreatments,
    treatments,
    results,
    enableCarousel,
    carousel,
    details,
    enableFeatured,
    featured,
    faqs,
    getInTouch,
    getInTouchOverride,
  } = data.wpPage.acfTemplateTreatmentDetail.treatmentDetail;
  const { breadcrumbs } = data.wpPage.seo;

  const { button: getInTouchButton } = getInTouch;
  const getInTouchProps =
    getInTouchOverride != null
      ? {
          preheading: getInTouch.preheading,
          heading: getInTouch.heading,
          description: getInTouch.description,
          ...(getInTouch.buttonOverride != null
            ? {
                primaryCta: {
                  label: getInTouchButton.label,
                  href: getInTouchButton.link,
                  target: getInTouchButton.target,
                },
              }
            : undefined),
        }
      : { heading: defaultHeading(data.wpPage.title) };

  const { RestrictedModal, store } = createRestrictedModal({
    acfSharedPage: data.wpPage.acfSharedPage,
  });

  return (
    <>
      <Main isBlurred={store.isVisible}>
        <JsonLD
          acfSharedPage={data.wpPage.acfSharedPage}
          breadcrumbs={breadcrumbs}
          title={data.wpPage.title}
          faqs={
            faqs && faqs.items && faqs.items.length ? faqs.items.map(x => FAQBlocks(x)) : undefined
          }
        />
        <BannerDetail
          heading={banner.heading}
          description={banner.description}
          image={banner.image}
          table={banner.table}
          cta={banner.cta}
          breadcrumbs={{ breadcrumbs }}
        />

        {enableVideo && video && (
          <Section backgroundColor="navy" className={styles.videoSection}>
            <Container>
              <Row center="sm">
                <Col sm={12}>
                  <TextPriority3 tagName="h2" bottomSpacing={Spacing.SPACING_24} color="white">
                    {video.title}
                  </TextPriority3>
                </Col>
              </Row>
              <Vimeo caption={video.vimeo.caption} id={video.vimeo.id} />
            </Container>
          </Section>
        )}

        <Section
          backgroundColor="grey"
          className={classNames(styles.centered, { [styles.hasVideo]: enableVideo })}>
          <Container>
            <WYSIWYG html={introduction.rte} />
          </Container>
        </Section>

        <Section backgroundColor="white">
          <Container>
            <Row>
              <Col sm={12} md={6} lg={5}>
                <TextPriority4>{benefits.heading}</TextPriority4>
                {benefits.rte && (
                  <div className={styles.benefitsRTE}>
                    <WYSIWYG html={benefits.rte} />
                  </div>
                )}
                {benefits.items && benefits.items.length > 0 && (
                  <UnorderedList>
                    {benefits.items.map((x, i) => (
                      <li key={i}>{x.benefit}</li>
                    ))}
                  </UnorderedList>
                )}
              </Col>
              <Col sm={12} md={6} lg={6} lgOffset={1}>
                <Picture
                  type="fluid"
                  fluid={benefits.image?.localFile.childImageSharp.fluid}
                  placeholder="285x190"
                />
              </Col>
            </Row>
          </Container>
        </Section>

        {enableTreatments && treatments && (
          <FeaturedTreatment
            heading={treatments.heading}
            description={treatments.description}
            items={treatments.items ? treatments.items : []}
            backgroundColor="pale"
          />
        )}

        <Section
          className={classNames(styles.centered, { [styles.marginOffset]: !enableTreatments })}
          backgroundColor="white">
          <Container>
            <TextPriority2 bottomSpacing={Spacing.SPACING_16}>{results.heading}</TextPriority2>
            <WYSIWYG html={results.rte} />
          </Container>
        </Section>

        {enableCarousel && carousel && (
          <div className={styles.carouselContainer}>
            <Carousel
              className={styles.carousel}
              caption={carousel.caption ?? undefined}
              items={carousel.items}
            />
          </div>
        )}

        <Faq
          className={classNames({ [styles.marginOffset]: !enableCarousel })}
          heading={details.heading}
          preheading={details.preheading}
          questions={details.items ? details.items : []}
        />

        {enableFeatured && featured && (
          <Featured
            heading={featured.heading}
            subheading={featured.subheading}
            items={
              featured.items
                ? featured.items.map(x => ({
                    ...x,
                    link: x.link != null ? x.link : undefined,
                  }))
                : []
            }
            md={6}
            cta={{
              label: featured.cta.label,
              url:
                featured.cta.urlType === 'custom' ? featured.cta.urlCustom : featured.cta.url.slug,
            }}
          />
        )}

        {faqs && faqs.items && faqs.items.length > 0 && (
          <Faq
            heading={faqs.heading}
            preheading={faqs.preheading}
            questions={faqs.items ? faqs.items.map(x => FAQBlocks(x)) : []}
            className={classNames({ [styles.marginOffset]: !enableFeatured })}
          />
        )}

        <GetInTouch {...getInTouchProps} />
      </Main>
      <RestrictedModal />
    </>
  );
};
