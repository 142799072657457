import { TreatmentDetail } from 'ui/components/templates/treatment_detail/treatment_detail';
import { graphql } from 'gatsby';
import { WPImageFluid } from 'shared/types/wp_localImage';
import { WPPageFeatureLink } from 'shared/types/wp_link';

type RelationalFAQ = {
  fieldGroupName: 'page_Acftemplatetreatmentdetail_TreatmentDetail_Faqs_Items_Relational';
  relational: {
    acf: {
      faq: {
        question: string;
        answer: string;
      };
    };
  };
};

type IndividualFAQ = {
  fieldGroupName: 'page_Acftemplatetreatmentdetail_TreatmentDetail_Faqs_Items_Individual';
  answer: string;
  question: string;
};

export type FAQBlocks = IndividualFAQ | RelationalFAQ;

export type PageTreatmentDetailProps = {
  acfTemplateTreatmentDetail: {
    treatmentDetail: {
      banner: {
        heading: string;
        description: string;
        image: null | WPImageFluid;
        table: {
          cell1: {
            label: string;
            value: string;
          };
          cell2: {
            label: string;
            value: string;
          };
          cell3: {
            label: string;
            value: string;
          };
          cell4: {
            label: string;
            value: string;
          };
        };
        cta: {
          label: string;
          url: string;
        };
      };
      enableVideo: boolean | null;
      video: null | {
        title: string;
        vimeo: {
          id: string;
          caption?: string;
        };
      };
      introduction: {
        rte: string;
      };
      benefits: {
        heading: string;
        image: null | WPImageFluid;
        rte: string | null;
        items:
          | null
          | {
              benefit: string;
            }[];
      };
      enableTreatments: boolean | null;
      treatments: null | {
        heading: string;
        description: string;
        items:
          | null
          | {
              treatment: WPPageFeatureLink;
            }[];
      };
      results: {
        heading: string;
        rte: string;
      };
      enableCarousel: boolean | null;
      carousel: null | {
        caption: string | null;
        items: {
          image: null | WPImageFluid;
        }[];
      };
      details: {
        preheading: string;
        heading: string;
        items:
          | null
          | {
              question: string;
              answer: string;
            }[];
      };
      enableFeatured: boolean | null;
      featured: null | {
        subheading: string;
        heading: string;
        cta: {
          label: string;
          urlType: 'relational' | 'custom';
          urlCustom: string;
          url: {
            slug: string;
          };
        };
        items:
          | null
          | {
              heading: string;
              description: string;
              subheading: string;
              link: string | null; // URL field in WP
              image: null | WPImageFluid;
            }[];
      };
      faqs: null | {
        preheading: string;
        heading: string;
        items: null | FAQBlocks[];
      };
      getInTouchOverride: boolean | null;
      getInTouch: {
        preheading: string;
        heading: string;
        description: string;
        buttonOverride: boolean | null;
        button: {
          label: string;
          link: string;
          target: '_blank' | '_self';
        };
      };
    };
  };
};

export const query = graphql`
  query TreatmentDetailPageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      ...PageContent
      acfTemplateTreatmentDetail {
        treatmentDetail {
          banner {
            heading
            description
            image {
              localFile {
                ...FluidImageFull
              }
            }
            table {
              cell1 {
                label
                value
              }
              cell2 {
                label
                value
              }
              cell3 {
                label
                value
              }
              cell4 {
                label
                value
              }
            }
            cta {
              label
              url
            }
          }
          enableVideo
          video {
            title
            vimeo {
              id
              caption
            }
          }
          introduction {
            rte
          }
          benefits {
            heading
            image {
              localFile {
                ...FluidImageFull
              }
            }
            rte
            items {
              benefit
            }
          }
          enableTreatments
          treatments {
            heading
            description
            items {
              treatment {
                ...PageFeatureLink
              }
            }
          }
          results {
            heading
            rte
          }
          enableCarousel
          carousel {
            caption
            items {
              image {
                localFile {
                  ...FluidImageFull
                }
              }
            }
          }
          details {
            preheading
            heading
            items {
              question
              answer
            }
          }
          enableFeatured
          featured {
            subheading
            heading
            cta {
              label
              urlType
              urlCustom
              url {
                ... on WpPage {
                  slug: uri
                }
              }
            }
            items {
              heading
              description
              subheading
              link
              image {
                localFile {
                  ...FluidImageFull
                }
              }
            }
          }
          faqs {
            preheading
            heading
            items {
              ... on WpPage_Acftemplatetreatmentdetail_TreatmentDetail_Faqs_Items_Relational {
                fieldGroupName
                relational {
                  ... on WpFaq {
                    acf: acfFAQs {
                      faq {
                        question
                        answer
                      }
                    }
                  }
                }
              }
              ... on WpPage_Acftemplatetreatmentdetail_TreatmentDetail_Faqs_Items_Individual {
                fieldGroupName
                answer
                question
              }
            }
          }
          getInTouchOverride
          getInTouch {
            preheading
            heading
            description
            buttonOverride
            button {
              label
              link
              target
            }
          }
        }
      }
    }
    wpGlobal: wp {
      ...WpGlobal
    }
  }
`;

export default TreatmentDetail;
