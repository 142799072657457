import * as React from 'react';
import classNames from 'classnames';
import { PrimaryButtonLink } from 'ui/components/atoms/link/link';
import { ScrollButton } from 'ui/components/atoms/scroll_button/scroll_button';
import styles from './book_enquire.module.css';

type BookEnquireProps = {
  className?: string;
  label?: string;
  href?: string;
  scrollCta?: {
    label: string;
    onClick?: () => void;
  };
};

export const BookEnquire = ({
  label = 'Book Consultation',
  href = '/',
  scrollCta = { label: 'Enquire now' },
  className,
}: BookEnquireProps) => (
  <div className={classNames(styles.container, className)}>
    <div className={styles.cta}>
      <PrimaryButtonLink href={href}>{label}</PrimaryButtonLink>
      <ScrollButton
        onClick={scrollCta.onClick}
        className={styles.scrollCta}
        subheading={scrollCta.label}
      />
    </div>
  </div>
);
