import React, { useContext } from 'react';
import { Container } from 'ui/components/organisms/container/container';
import {
  TextPriority2,
  TextRegularAlt,
  Spacing,
  TextSub1,
  TextSmall,
} from 'ui/components/atoms/typography/typography';
import { Row, Col } from 'ui/components/organisms/grid/grid';
import { Quote } from 'ui/components/atoms/quote/quote';
import { BookEnquire } from 'ui/components/molecules/book_enquire/book_enquire';
import styles from './banner_detail.module.css';
import { Breadcrumbs, BreadcrumbsProps } from '../breadcrumbs/breadcrumbs';
import { Picture } from 'ui/components/atoms/picture/picture';
import { WPImageFluid } from 'shared/types/wp_localImage';
import { WatermarksBanner } from 'ui/components/atoms/watermarks/watermarksBanner';
import { scrollToElement } from 'util/scrollTo';
import { HtmlBreaks } from 'util/html';
import { store } from 'ui/store/store';

export type BannerDetailProps = {
  breadcrumbs: BreadcrumbsProps;
  heading: string;
  description: string;
  table: {
    cell1: {
      label: string;
      value: string;
    };
    cell2: {
      label: string;
      value: string;
    };
    cell3: {
      label: string;
      value: string;
    };
    cell4: {
      label: string;
      value: string;
    };
  };
  image: null | WPImageFluid;
  cta: {
    label: string;
    url: string;
  };
};

const createEnquireClick = (element?: HTMLDivElement) => () => {
  if (element != null) {
    scrollToElement(element, 74);
  }
};

export const BannerDetail = ({
  image,
  description,
  heading,
  table,
  breadcrumbs,
  cta,
}: BannerDetailProps) => {
  const { state } = useContext(store);
  const enquireClick = createEnquireClick(state.refNodes.footerEnquire);
  return (
    <div className={styles.banner}>
      <div className={styles.bannerInner}>
        {image ? (
          <WatermarksBanner
            variant="O"
            aspectRatio={1 / image.localFile.childImageSharp.fluid.aspectRatio}
          />
        ) : null}
        <Container>
          <Row>
            <Col sm={12} md={6} lg={5} lgOffset={1}>
              <div className={styles.textContent}>
                <Breadcrumbs {...breadcrumbs} />
                <TextPriority2 tagName="h1" bottomSpacing={Spacing.SPACING_16}>
                  {heading}
                </TextPriority2>
                <TextRegularAlt bottomSpacing={Spacing.SPACING_24}>
                  {HtmlBreaks(description)}
                </TextRegularAlt>
                <Quote className={styles.quote}>
                  <Row>
                    <Col sm={4}>
                      <TextSub1>{table.cell1.label}</TextSub1>
                      <TextSmall className={styles.small} color="navy" tagName="div">
                        {table.cell1.value}
                      </TextSmall>
                    </Col>
                    <Col sm={4}>
                      <TextSub1>{table.cell2.label}</TextSub1>
                      <TextSmall className={styles.small} color="navy" tagName="div">
                        {table.cell2.value}
                      </TextSmall>
                    </Col>
                    <Col sm={4}>
                      <TextSub1>{table.cell3.label}</TextSub1>
                      <TextSmall className={styles.small} color="navy" tagName="div">
                        {table.cell3.value}
                      </TextSmall>
                    </Col>
                    <Col sm={12}>
                      <TextSub1>{table.cell4.label}</TextSub1>
                      <TextSmall color="navy" tagName="div">
                        {table.cell4.value}
                      </TextSmall>
                    </Col>
                  </Row>
                </Quote>
                <BookEnquire
                  scrollCta={{ label: 'Enquire Now', onClick: enquireClick }}
                  label={cta.label}
                  href={cta.url}
                  className={styles.bookEnquire}
                />
              </div>
            </Col>
            <Col className={styles.imageColumn} sm={12} md={6} lg={5}>
              <Picture
                type="fluid"
                placeholder="285x190"
                className={styles.image}
                fluid={image?.localFile.childImageSharp.fluid}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
